<template>
    <div class='news'>
      <div class='newscontent'>
        <h3> {{ dateformat(date) }} </h3>
        <h2> {{ title }} </h2>
        <div class='newscontenttext' v-html='summary'></div>
        <transition-expand>
          <div v-show='isExpanded' class='newscontenttext' v-html='text'></div>
        </transition-expand>
        <a id='newscontentexpand' v-if='text' @click='isExpanded=!isExpanded'>
          {{ isExpanded ? "Details ausblenden" : "Details anzeigen" }}
        </a>
      </div>
    </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand.vue'

export default {
  name: 'NewsContent',
  props: ['title', 'summary', 'text', 'date'],
  components: {
      TransitionExpand
  },
  data: function () {
    return {
      isExpanded: false
    }
  },
  methods: {
    dateformat: function(date) {
      var split = (new Date(date * 1000).toISOString().substr(0, 10)).split("-");
      return parseInt(split[2]) + "." + parseInt(split[1]) + "." + split[0];
    }
  },
}
</script>

<style>
.news {
  flex: 0 0 auto;
  width: calc(33.3333333% - 20px);
  background-color: var(--medium-gray);
  border-radius: 5px;
  margin: 32px 10px 30px 10px;
  position: relative;
}

@media (max-width: 725px) {
  .news {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }
}

.news:before {
  content:'';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--medium-gray);
  border-top: 10px solid var(--medium-gray);
  border-bottom: 10px solid transparent;
  right: 19px;
  bottom: -19px;
}

.news h2 {
  font-family: 'Oswald-Medium', sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

.news h3 {
  font-family: 'Oswald-Medium', sans-serif;
  font-size: 0.9em;
  font-weight: 100;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
}

.news p {
  margin-top: 10px;
  margin-bottom: 0px;
}

.newscontent {
  padding: 20px;
  font-size: 0.9em;
  overflow: hidden;
  transition: all 2.5s;
}

.newscontent a {
  text-decoration: none;
  color: var(--clown-red);
}

.newscontent a:hover {
  cursor: pointer;
}

#newscontentexpand {
  float: left;
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 100;
  font-style: italic;
  color: var(--dark-gray);
}
</style>