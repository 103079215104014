<template>
    <div class='row' >
      <article v-if="sponsoren.length > 0">
        <div class='sponsoren-list'>
            <h2>Der Zirkus Orlando wird aktuell unterstützt von:</h2>
            <div class="sponsoren-entry" v-for="(item, index) of sponsoren" :key="index">
            <p> {{ item.name }}</p>
            <a :href="item.link">
                <img :src="item.image.src"/>
            </a>
            </div>
        </div>
      </article>
    </div>
</template>

<script>
export default {
    name: 'PartnerContent',
  data: function () {
    return {
      sponsoren: []
    }
  },
  created: function() {
    var vm = this;
    
    // Fetch sponsoren
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/Sponsoren?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      for (var entry of data.entries) {
        if (entry.Aktiv) {            
          var image = new Image(); // prefetch
          image.src = "https://zirkus-orlando.de/cockpit/api/cockpit/image?token=125a1aa046027a483fa93c45e4fc2a&src=" + entry.Logo.path + "&w=1000&m=bestFit&o=true";
          vm.sponsoren.push({'name': entry.Name, 'link': entry.Link, 'image': image});
        }
      }
    });
  },
}
</script>

<style>

.sponsoren-list {
  width: 100%;
  text-align: left;
}

.sponsoren-entry {
  display: inline-block;
  position: relative;
  width: 100%;

  left: 0px;
  margin-left: auto; 
  margin-right: auto;
}

.sponsoren-entry img {
  position: relative;
  width: 25%;
  min-width: 220px;
  border-radius: 5px;
}

</style>