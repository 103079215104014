<template>
    <transition name='expand' @enter='enter' @after-enter='afterEnter' @leave='leave'>
        <slot :style='hwaccel'/>
    </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',
  data: function () {
    return {
      hwaccel: {
        'will-change': 'height',
        'transform': 'translateZ(0)',
        'backface-visibility': 'hidden',
        'perspective': '1000px'
      }
    }
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      const height = getComputedStyle(element).height;
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height; // Force repaint
      requestAnimationFrame(() => {
        element.style.height = height;
      })
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;
      element.style.height = height;
      getComputedStyle(element).height; // Force repaint
      requestAnimationFrame(() => {
        element.style.height = 0;
      })
    }
  }
}
</script>

<style>
.expand-enter-active, .expand-leave-active {
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.expand-enter-from, .expand-leave-to {
  height: 0;
  opacity: 0;
  transform: translateX(10px) scale(0.95);
}
</style>
