<template>
    <div>
      <hr class='divider'>
      <footer>
        <a href='https://www.facebook.com/ZirkusOrlando/' title='facebook'>facebook</a>
        <a href='https://www.instagram.com/zirkus.orlando/' title='Instagram'>Instagram</a>
        <router-link to='/kontakt/impressum' > Impressum </router-link>
      </footer>
    </div>
</template>

<script>
export default {
  name: 'LinkFooter'
}
</script>

<style scoped>
footer {
  margin-top: 0px;
  margin-bottom: -10px;
  padding: 20px;
}

footer a {
  text-decoration: none;
  color: var(--dark-gray);
  margin-right: 20px;
}

footer a:hover {
  color: var(--clown-red);  
}

.divider {
  border: 3px solid var(--medium-gray);
  border-radius: 5px;
  margin-bottom: 0px;
  margin-bottom: 0px;
}
</style>