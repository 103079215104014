<template>
    <div id='weekplandiv'>
      <div class='weekplan'>
        <table>
          <tr>
            <th v-for='day in weekdays' :key='day'> {{ day }} </th>
          </tr>
          <tr v-for='i in longestday' :key='i'>
            <td v-for='day of weekdays' :key='day'>
              <TrainingInfo v-if='data[day].length >= i'
                :data='data[day][i-1]'
                :key='data[day][i-1]._id'
              ></TrainingInfo>
            </td>
          </tr>
        </table>
      </div>
      <div class='weeklist'>
        <div v-for='day of weekdays' :key='day'>
          <h1 v-if='data[day].length > 0'>
            {{ day }}
          </h1>
          <div v-if='data[day].length > 0' class='weeklistday'>
            <TrainingInfo v-for='entry in data[day]'
              :data='entry'
              :key='entry._id'
            ></TrainingInfo>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import TrainingInfo from '@/components/TrainingInfo.vue'

export default {
  name: 'WeekPlan',
  components: {
      TrainingInfo
  },
  data: function() {
    return {
      weekdays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
      weekdaynames: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
      data: {"Mo": [], "Di": [], "Mi": [], "Do": [], "Fr": [], "Sa": [], "So": []}
    }
  },
  computed: {
    longestday: function() {
      var longest = 0;
      for (var day of this.weekdays) {
        if (this.data[day].length > longest) {
          longest = this.data[day].length;
        }
      }
      return longest;
    }
  },
  created: function () {
    var vm = this;
    
    // Fetch events
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/Trainingsplan?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      for (var i = 0; i < vm.weekdays.length; i++) {
        vm.data[vm.weekdays[i]] = data.entries.filter(function(entry) {
          return !entry.Deaktiviert && (entry.Wochentag === vm.weekdaynames[i]);
        });
        vm.data[vm.weekdays[i]] = vm.data[vm.weekdays[i]].sort(function(a, b) {
          if (a.StartZeit < b.StartZeit) {
            return -1;
          }
          else if (a.StartZeit > b.StartZeit) {
            return 1;
          }
          else {
            return 0;
          }
        });
      }
    });
  }
}
</script>

<style>
.weekplan {
  background: var(--medium-gray);
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.weekplan table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: 'Oswald-Regular', sans-serif;
  text-align: left;
}

.weekplan th {
  font-weight: normal;
  padding: 10px 10px;
  margin: 0;
  text-align: center;
  background: #e0e0e0;
}

.weekplan th:first-child{
  border-top-left-radius: 5px;
}

.weekplan th:last-child{
  border-top-right-radius: 5px;
}

.weekplan td {
  vertical-align: top;
  font-family: 'NotoSans-Regular', sans-serif;
  color: var(--dark-gray);
  width: calc(1.0 / 7.0 * 100.0%);
  padding: 10px 10px;
  margin: 0;
}

.weeklist {
  display: none;
}

.weeklist h1 {
  font-family: 'Oswald-Medium', sans-serif;
  color: var(--clown-red);
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.weeklistday .traininginfo {
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .weekplan {
    display: none;
  }

  .weeklist {
    display: block;
  }
}
</style>