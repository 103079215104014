<template>
  <BannerSlideshow
    :collection='"Slideshow"'
    :tag='"Startseite"'
    :height='"500px"'
  ></BannerSlideshow>

  <main style='margin-bottom:40px'>
    <div class='row'>
      <div class='grid'>
        <NewsContent class='news'
          v-if='startpage_news.length > 0'
          :title='startpage_news[0].Titel'
          :summary='startpage_news[0].Zusammenfassung'
          :text='startpage_news[0].Inhalt'
          :date='startpage_news[0]._created'
          :key='startpage_news[0]._id'
        ></NewsContent>
        <article id='homepagecontent'>
          <div class='page-content' v-if='startpage_content'>
            <h1> {{ startpage_content.Titel }} </h1>
            <div v-html='startpage_content.Inhalt'></div>
          </div>  
        </article>
      </div>
    </div>
  </main>
</template>

<script>
import BannerSlideshow from '@/components/BannerSlideshow.vue'
import NewsContent from '@/components/NewsContent.vue'

export default {
  name: 'Home',
  props: ['menu_items', 'pages'],
  components: {
    BannerSlideshow,
    NewsContent
  },
  data: function() {
    return {
      startpage_news: []
    }
  },
  created: function() {
    var vm = this;
    
    // Fetch news
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/News?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      var news = data.entries.sort(function(a, b) {
        if (a._created < b._created) {
          return -1;
        }
        else if (a._created > b._created) {
          return 1;
        }
        else {
          return 0;
        }
      }).reverse();

      for (var element of news) {
        if (element.Startseite) {
          vm.startpage_news.push(element);
        }
      }
    });
  },
  computed: {
    startpage_content: function () {
      return this.pages.find(page => page.ID === "start")
    }
  }
}
</script>

<style>
#homepagecontent {
  flex: 0 0 auto;
  width: calc(2.0 * 33.3333333% - 20px);
  margin: 20px 10px;
  position: relative;
}

#homepagecontent a {
  text-decoration: none;
  color: var(--clown-red);
}

@media (max-width: 725px) {
  #homepagecontent {
    width: calc(100% - 20px);
    margin-top: 10px;
  }
}
</style>
