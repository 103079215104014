<template>
    <header>
      <div class='logo'>
        <router-link to='/'>
          <img src="@/assets/logo.png" alt='Zirkus Orlando Abteilung Zirkussport TV Hackenberg 1891 e.V.'/>
        </router-link>
      </div>
      <nav class='menu'>
        <ul>
          <li v-for='item in main_menu_items' :key='item._id'>
            <router-link :to='item.Link' :class='{selected: item.Link === current_route_hierarchy[0], highlight: item.ID === "gala2023" }'> {{ item.Name }} </router-link>
          </li>
        </ul>
      </nav>
      <div class='row' v-for='(path, index) in current_route_submenus' :key='path'>
        <nav class='sub-menu rounded'>
          <ul>
            <li v-for='item in get_sub_menu_items(path)' :key='item._id'>
              <router-link :to='item.Link' :class='{selected: item.Link === current_route_hierarchy[index + 1] || item.Link === $route.path}'> {{ item.Name }} </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
</template>

<script>

export default {
  name: 'LogoMenuHeader',
  props: ['menu_items'],
  computed: {
    current_route_hierarchy: function () {
      var split = this.$route.path.split("/")
      var hierarchy = []
      for (let i = 0; i < split.length - 1; i++) {
        var s = ""
        for (let j = 1; j < i + 2; j++) {
          s += "/" + split[j]
        }
        hierarchy.push(s)
      }
      return hierarchy
    },
    main_menu_items: function () {
      return this.menu_items.filter(item => item.HauptMenue === true)
    },
    current_route_submenus: function () {
      return this.current_route_hierarchy.filter(route => this.get_sub_menu_items(route).length > 0)
    }
  },
  methods: {
    get_sub_menu_items: function (path) {
      var current_menu_item = this.menu_items.find(item => item.Link === path)
      if (!current_menu_item) return []
      if (!current_menu_item.UnterElemente || current_menu_item.UnterElemente.length === 0) return []
      var sub = []
      for (var sub_element of current_menu_item.UnterElemente)  {
        sub.push(this.menu_items.find(menu_item => menu_item._id === sub_element._id))
      }
      return sub
    }
  }
}
</script>

<style scoped>
header {
  margin-bottom: 0px;
}

.logo {
  display: inline;
}

.logo img {
  height: 145px;
}

.menu {
  float: right;
  margin-top: 110px;
}

.menu ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style: none;
}

.menu li {
  cursor: pointer;
  display: inline;
  color: var(--dark-gray);
}

.menu a {
  color: var(--dark-gray);
  font-family: 'Oswald-Medium', sans-serif;
  font-weight: regular;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 20px;
}

.menu a.selected {
  color: var(--clown-red);
  border-bottom: 3px solid var(--clown-red);
}

.menu a:hover {
  color: var(--clown-red);
}

.menu a.highlight {
  background-color: var(--clown-red);
  color: var(--medium-gray);
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
}
.menu a.highlight.selected {
  color: var(--clown-red);
  background-color: var(--medium-gray);
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  border-bottom: 3px solid var(--clown-red);
}

.menu a.highlight:hover {
  background-color: var(--dark-gray);
}
.menu a.highlight.selected:hover {
  color: var(--dark-gray);
  background-color: var(--medium-gray);
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  border-bottom: 3px solid var(--clown-red);
}
.sub-menu {
  background-color: var(--medium-gray);
  padding: 10px;
  width: 100%;
  position:relative;
  font-size: 0.9em;
}

.sub-menu ul {
  padding-left: 0px;
  list-style: none;
}

.sub-menu li {
  cursor: pointer;
  display: inline;
}

.sub-menu a {
  color: var(--dark-gray);
  font-family: 'Oswald-Medium', sans-serif;
  text-transform: uppercase;
  font-weight: regular;
  text-decoration: none;
  margin-left: 20px;
}

.sub-menu a.selected {
  color: var(--clown-red);
  border-bottom: 3px solid var(--clown-red);
}

.sub-menu a:hover {
  color: var(--clown-red);
}

@media (max-width: 865px) {
  .logo {
  }

  .logo img {
    height: 130px;
    margin-top: 35px;
  }

  .menu {
    margin-top: 25px;
  }

  .menu a {
    font-size: 0.9em;
    margin-left: 0px;
  }

  .menu li {
    display: list-item;
    text-align: right;
    margin-right: 10;
  }
}

@media (max-width: 725px) {
  .sub-menu li {
    display: list-item;
    text-align: left;
    margin-left: 10;
  }
}

</style>
