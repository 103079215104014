<template>
    <div>
      <div class='calendar'>
        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FBerlin&showPrint=0&showTz=0&title=Zirkus%20Orlando&src=NzQ0YjhiMmEzNzdlMDNhYmVmOTVmMjM5ZTYxODk4ZDg5OTM2MmM5NjNkNWVhYWE3NzMzMzJlNGFkMzFlMjM0ZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=Yjc2ZmZiNDIzNGRhMDY4OTFmMzllNzM3MzcyYjY1M2RmYmM0MDAyZjBjMjAxNWE3OWNlZmU5ZDMzMjIyYWNjZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=Mjk0NzI0NzBlMDRhYzNiMzcxMjg4NjM5OGU4ZmU3YjdmY2ZmOWZlODM5NTdkZDMyMDIwOTM1ODU4YjlmYTdiM0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NzUyMDJlNDBmNDdhODE0YzBiNzU5YmYzYTUyMjY4YjU4MWNiZTI5NmRiNjczMjEwN2RhOTFmNWVjYWMwNzI0MEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=MzE5ZjY4OGMzMDQxYjExMjkyM2JhNzNkZTIzMDE2NzA3YTI0NDJlNGU5NjBmMWMyYWZkNjNhNzBhZmI0YTRhN0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=MzExMzIyZDVkZmRjZGY0NzkwNjJjYjAyODcwOWU5YmRlZmVkYzk1NGEyZDQ3MGM2NWNjM2Y5MTVjNTRmZWQzZkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=MzE4ZTU4MDlhYjYzOWZhZjYyNDFiZGRiMDlmYmVmODQ2NTUwNTc1Y2JiMTRiZjNlNmY1OGQ3MTllYjRiYTE2ZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%2333B679&color=%23D50000&color=%23F09300&color=%23B39DDB&color=%233F51B5&color=%23F6BF26&color=%23AD1457" frameborder="0" scrolling="no"></iframe>
        <!-- <ul class='monthselect'>
          <li class='prev' @click='previousmonth'>&#10094;</li>
          <li>
            {{ monthstring }}<br>
            <span> {{ activeyear }} </span>
          </li>
          <li class='next' @click='nextmonth'>&#10095;</li>
        </ul>

        <ul class="weekdays">
          <li v-for='day in weekdays' :key='day'> {{ day }} </li>
        </ul>

        <ul class="days">
          <li v-for='d in monthstartson - 1' :key='d'></li>
          <li v-for='d in daysinmonth' :key='d' @click='activateday(d)'>
            <span :class='{
                today: istoday(d),
                active: activeday === d,
                hasevent: dayhasevent(d)
              }'
            >
              {{ d }}
            </span>
          </li>
        </ul> -->
      </div>
      
      <!-- <transition-group name='eventlistlist' tag='div' id='eventlistlistdiv'>
        <div :key='"selected"'>
          <transition name='eventlistanim'>
            <div class='eventlist' v-show='dayhasevent(activeday)'>
              <h1 :key='"heading"' v-show='dayhasevent(activeday)'>
                Events am {{ activeday }}. {{ monthnames[activemonth] }} {{ activeyear }}:
              </h1>
              <div class='row' :key='"grid"' v-show='dayhasevent(activeday)'>
                <div class='grid'>
                  <EventContent
                    v-for='event in eventsforday(activeday)'
                    :event='event'
                    :key='event._id'
                  ></EventContent>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div :key='"upcomming"'>
          <transition name='eventlistanim'>
            <div class='eventlist' v-show='upcommingevents.length > 0'>
              <h1 v-show='upcommingevents.length > 0'>
                Alle zukünftigen Events:
              </h1>
              <div class='row' v-show='upcommingevents.length > 0'>
                <div class='grid'>
                  <EventContent
                    v-for='event in upcommingevents'
                    :event='event'
                    :key='event._id'
                  ></EventContent>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition-group> -->
    </div>    
</template>

<script>
// import EventContent from '@/components/EventContent.vue'

export default {
  name: 'CalendarContent',
  components: {
      // EventContent
  },
  data: function () {
    return {
      activeday: 0,
      activemonth: 0,
      activeyear: 0,
      monthnames: ["Januar",
                    "Februar",
                    "März",
                    "April",
                    "Mai",
                    "Juni",
                    "Juli",
                    "August",
                    "September",
                    "Oktober",
                    "November",
                    "Dezember"],
      weekdays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
      events: [],
      upcommingevents: []
    }
  },
  computed: {
    date: function () {
      return new Date().getDate();
    },
    month: function () {
      return new Date().getMonth();
    },
    year: function () {
      return new Date().getFullYear();
    },
    monthstring: function () {
      return this.monthnames[this.activemonth];
    },
    daysinmonth: function () {
      return new Date(this.activeyear, this.activemonth+1, 0).getDate();
    },
    monthstartson: function () {
      var day = new Date(this.activeyear, this.activemonth, 1).getDay();
      return (day > 0) ? day : 7;
    }
  },
  methods: {
    activateday: function (d) {
      this.activeday = d;
    },
    previousmonth: function () {
      this.activeday = 0;
      this.activemonth -= 1;
      if (this.activemonth < 0) {
        this.activemonth = 11;
        this.activeyear -= 1;
      }
    },
    nextmonth: function () {
      this.activeday = 0;
      this.activemonth += 1;
      if (this.activemonth > 11) {
        this.activemonth = 0;
        this.activeyear += 1;
      }
    },
    istoday: function (d) {
      return (this.date === d && this.month === this.activemonth && this.year === this.activeyear);
    },
    daystring: function (d) {
      return this.activeyear + "-" + String(this.activemonth + 1).padStart(2, "0") + "-" + String(d).padStart(2, "0");
    },
    dayhasevent: function (d) {
      return this.eventsforday(d).length > 0;
    },
    eventsforday: function (d) {
      var vm = this;
      return vm.events.filter(function(event) {
        var event_start_split = event.StartDatum.split("-");
        var event_end_split = event.EndDatum ? event.EndDatum.split("-") : event_start_split;

        return ((parseInt(event_start_split[0]) <= vm.activeyear) &&
               (vm.activeyear <= parseInt(event_end_split[0])) &&
               (parseInt(event_start_split[1]) <= vm.activemonth + 1) &&
               (vm.activemonth + 1 <= parseInt(event_end_split[1])) &&
               (parseInt(event_start_split[2]) <= d) &&
               (d <= parseInt(event_end_split[2])));
      })
    }
  },
  created: function () {
    var vm = this;
    
    vm.activeday = vm.date;
    vm.activemonth = vm.month;
    vm.activeyear = vm.year;
    
    // Fetch calendar entries
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/Events?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      vm.events = data.entries;
    
      // Filter upcomming events
      vm.upcommingevents = vm.events.filter(function(event) {
        var event_start_split = event.StartDatum.split("-");
        var event_start_date = new Date(parseInt(event_start_split[0]), parseInt(event_start_split[1]) - 1, parseInt(event_start_split[2]));
        var current_date = new Date(vm.year, vm.month, vm.date);
        return current_date <= event_start_date;
      });

      vm.upcommingevents = vm.upcommingevents.sort(function(a, b) {
        var a_start_split = a.StartDatum.split("-");
        var a_start_date = new Date(parseInt(a_start_split[0]), parseInt(a_start_split[1]) - 1, parseInt(a_start_split[2]));
        
        var b_start_split = b.StartDatum.split("-");
        var b_start_date = new Date(parseInt(b_start_split[0]), parseInt(b_start_split[1]) - 1, parseInt(b_start_split[2]));

        if (a_start_date < b_start_date) {
          return -1;
        }
        else if (a_start_date > b_start_date) {
          return 1;
        }
        else {
          return 0;
        }
      });
    });
  }
}
</script>

<style>
.calendar {
  /* background: var(--medium-gray); */
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 0px;
}

iframe {
  width:100%;
  height:600px 
}

ul.monthselect {
  list-style-type: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald-Medium', sans-serif;
  text-transform: uppercase;
  font-weight: regular;
  text-decoration: none;
  color: var(--dark-gray);
  
  padding: 10px;
  list-style: none;
  vertical-align: middle;
}

ul.monthselect li {
  display: inline-block;;
  vertical-align: middle;
}

ul.monthselect .next, .prev {
  cursor: pointer;
  padding: 4px 24px;
  user-select: none;
}

.slideshowprevious, .slideshownext {
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: calc(50% - 16px);
  width: auto;
  padding: 4px 12px;
  color: var(--dark-gray);
  background-color: var(--medium-gray);
  font-weight: bold;
  font-size: 36;
  font-family: 'NotoSans-Regular', sans-serif;
  user-select: none;
  border-radius: 5px;
  z-index: 2;
}

.slideshowprevious:hover, .slideshownext:hover {
  color: var(--clown-red);
}

.slideshowprevious {
  left: 10px;
}

.slideshownext {
  right: 10px;
}

.weekdays {
  list-style-type: none;
  background-color: #e0e0e0;
  font-family: 'Oswald-Regular', sans-serif;
  padding: 10px 0;
  margin: 0;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  text-align: center;
}

.days {
  user-select: none;
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
}

.days li {
  cursor: pointer;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 20px;
}

.days li .today {
  padding: 5px;
  background: var(--clown-red);
  border-radius: 5px;
  color: white !important
}

.days li .active {
  padding: 3px;
  border: 2px solid var(--clown-red);
  border-radius: 5px;
}

.days li .hasevent {
  padding: 4px;
  border-bottom: 2px solid var(--clown-red);
  border-radius: 5px;
}

.eventlist h1 {
  font-family: 'Oswald-Medium', sans-serif;
  color: var(--clown-red);
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.eventlistlist-move {
  transition: all 0.2s;
}

.eventlistanim-enter-active, .eventlistanim-leave-active {
  transition: all 0.4s;
}

.eventlistanim-enter, .eventlistanim-leave-to {
  opacity: 0.0;
  transform: translateX(10px) scale(0.95);
  max-height: 0;
}

.eventlistanim-enter-to, .eventlistanim-leave {
  opacity: 1;
  transform: translateX(0px) scale(1.0);
  max-height: 1000px;
}
</style>