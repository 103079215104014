<template>
  <BasePageDecoration/>
  <LogoMenuHeader :menu_items='menu_items'/>
  <router-view :menu_items='menu_items' :pages='pages'/>
  <LinkFooter/>
</template>

<script>
import BasePageDecoration from '@/components/BasePageDecoration.vue'
import LogoMenuHeader from '@/components/LogoMenuHeader.vue'
import LinkFooter from '@/components/LinkFooter.vue'

export default {
  components: {
    BasePageDecoration,
    LogoMenuHeader,
    LinkFooter
  },
  data: function () {
    return {
      menu_items: [],
      pages: []
    }
  },
  created: function () {
    var vm = this;
    
    // Fetch menu items
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/MenueElemente?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      vm.menu_items = data.entries
    });
    
    // Fetch pages
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/Seiten?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      vm.pages = data.entries;
    });
  },
}
</script>

<style>

</style>
