<template>
    <div v-if='family_names.length > 0'>
      <div class='familycontent rounded'>
        <div class='row'>
          <nav class='vert-menu rounded'>
            <ul>
              <li v-for='f in family_names.length' v-on:click='current_index=f-1' :class='{selected: f-1 === current_index}' :key='f'><a> {{ family_names[f-1] }} </a></li>
            </ul>
          </nav>
          <div class='familyslideshow'>
            <transition name='familyslide' mode='out-in'>
              <div class='slideshowimage' :style='{"background-image": "url(" + family_images[current_index].src + ")"}' :key='family_names[current_index]'/>
            </transition>
          </div>
        </div>
      </div>
      <div class='quote'>
        <div v-if='family_names.length > 0' v-html='families[family_names[current_index]].Beschreibung'></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FamilyContent',
  data: function () {
    return {
      families: {},
      family_names: [],
      family_images: [],
      current_index: 0
    }
  },
  created: function () {
    var vm = this;
    
    // Fetch content
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/Artistenfamilien?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      for (var entry of data.entries) {
        vm.families[entry.Name] = entry;
        vm.family_names.push(entry.Name);
      }
      vm.family_names = vm.family_names.sort(function(a, b) {
        return (a).localeCompare(b);
      })
      for (var family of vm.family_names) {
        var image = new Image();
        image.src = "https://zirkus-orlando.de/cockpit/api/cockpit/image?token=125a1aa046027a483fa93c45e4fc2a&src=" + vm.families[family].Bild.path + "&m=bestFit&h=500&o=true";
        vm.family_images.push(image);
      }
    });
  }
}
</script>

<style>
.vert-menu {
  flex: 0 1 15%;
  background-color: var(--medium-gray);
  padding: 10px;
  position: relative;
  font-size: 0.9em;
}

.vert-menu ul {
  padding-left: 0px;
  list-style: none;
}

.vert-menu li {
  cursor: pointer;
  display: list-item;
  font-family: 'Oswald-Medium', sans-serif;
  font-weight: regular;
  text-decoration: none;
  color: var(--dark-gray);
  margin-left: 20px;
  margin-right: 20px;
  min-width: 100%;
}

.vert-menu li:hover {
  color: var(--clown-red);
}

.vert-menu li.selected {
  color: var(--clown-red);
}

.familycontent {
  background-color: var(--medium-gray);
  max-width: 100%;
}

.familyslideshow {
  flex: 1 1 85%;
  overflow: hidden;
  border-radius: 5px;
  height: 500px;
  position: relative;
  background-color: #000000;
  backface-visibility: hidden;
}

.slideshowimage {
  background-size: cover;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

@media (max-width: 725px) {
  .familycontent .row {
    flex-flow: row wrap-reverse;
  }

  .vert-menu {
    flex: 1 1 100%;
    font-size: 0.8em;
  }

  .familyslideshow {
    flex: 1 1 100%;
    max-height: 260px;
  }
}

.familyslide-enter-active, .familyslide-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}

.familyslide-enter-from, .familyslide-leave-to {
  opacity: 0.1;
  transform: translateX(50px) scale(0.95);
}

.familyslide-enter-to, .familyslide-leave-from {
  opacity: 1.0;
  transform: translateX(0px) scale(1);
}

.quote {
  font-family: 'NotoSans-Regular', sans-serif;
  font-size: 1.0em;
  font-weight: 400;
  line-height: 1.5em;
  background-color: var(--medium-gray);
  border-radius: 5px;
  margin-top: 35px;
  position:relative;
  padding: 10px 25px 10px 75px;
}

.quote:before {
  font-family: 'Oswald-Regular', sans-serif;
  content: "\201C";
  color: var(--clown-red);
  font-size: 4em;
  font-style: italic;
  position: absolute;
  left: 15px;
  top: 40px;
}

.quote:after {
  content:'';
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 10px solid var(--medium-gray);
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid var(--medium-gray);
  left: 48px;
  top: -19px;
}
</style>