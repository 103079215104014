import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App                from './App.vue'
import Home               from './pages/home.vue'
import Portrait           from './pages/portrait.vue'
import News               from './pages/news.vue'
import Kalender           from './pages/kalender.vue'
import ArticleContent     from './components/ArticleContent.vue'
import BasePage           from './components/BasePage.vue'
import FamilyContent      from './components/FamilyContent.vue'
import WeekPlan           from './components/WeekPlan.vue'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/gala2023',
    name: 'Gala 2023',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'Gala 2023',
        component: ArticleContent
      },
      {
        path: 'zeitplan',
        name: 'Zeitplan',
        component: ArticleContent
      },
      {
        path: 'sponsoring',
        name: 'Sponsoring',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/portrait',
    name: 'Portrait',
    component: Portrait,
    children: [
      {
        path: '',
        name: 'Portrait',
        component: ArticleContent
      },
      {
        path: 'historie',
        name: 'Historie',
        component: ArticleContent
      },
      {
        path: 'abteilungsaufbau',
        name: 'Abteilungsaufbau',
        component: ArticleContent
      },
      {
        path: 'datenschutzkonzept',
        name: 'Datenschutzkonzept',
        component: ArticleContent
      },
      {
        path: 'ehrenkodex',
        name: 'Ehrenkodex',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/kalender',
    name: 'Kalender',
    component: Kalender
  },
  {
    path: '/training',
    name: 'Training',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'Trainingsplan',
        component: WeekPlan
      },
      {
        path: 'artistenfamilien',
        name: 'Artistenfamilien',
        component: FamilyContent
      },
      {
        path: 'orlandinos',
        name: 'Orlandinos',
        component: ArticleContent
      },
      {
        path: 'variete',
        name: 'Variete',
        component: ArticleContent
      },
      {
        path: 'mitmachen',
        name: 'Mitmachen',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/team',
    name: 'Team',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'Anleiter',
        component: ArticleContent
      },
      {
        path: 'vorstand',
        name: 'Vorstand',
        component: ArticleContent
      },
      {
        path: 'partner',
        name: 'Partner',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'Kontakt',
        component: ArticleContent
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/orlandinos',
    redirect: {name: 'Orlandinos'}
  },
  {
    path: '/variete',
    redirect: {name: 'Variete'}
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'Zirkus Orlando';
router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE + ' - ' + to.name;
  next();
});

createApp(App).use(router).mount('#app')
