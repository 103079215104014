<template>
  <main>
    <CalendarContent/>
  </main>
</template>

<script>
import CalendarContent from '@/components/CalendarContent.vue'

export default {
  name: 'Kalender',
  props: ['menu_items', 'pages'],
  components: {
    CalendarContent
  }
}
</script>

<style>

</style>
