<template>
  <main>
    <router-view :menu_items='menu_items' :pages='pages'/>
  </main>
</template>

<script>
export default {
  name: 'BasePage',
  props: ['menu_items', 'pages']
}
</script>

<style>

</style>
