<template>
  <main>
    <div class='row'>
      <div class='grid' id='newsgrid'>
        <NewsContent class='news'
          v-for='entry in news'
          :title='entry.Titel'
          :summary='entry.Zusammenfassung'
          :text='entry.Inhalt'
          :date='entry._created'
          :key='entry._id'
        ></NewsContent>
      </div>
    </div>
  </main>
</template>

<script>
import NewsContent from '@/components/NewsContent.vue'

export default {
  name: 'News',
  props: ['menu_items', 'pages'],
  components: {
    NewsContent
  },
  data: function () {
    return {
      news: []
    }
  },
  created: function() {
    var vm = this;
    
    // Fetch news
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/News?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      vm.news = data.entries.sort(function(a, b) {
        if (a._created < b._created) {
          return -1;
        }
        else if (a._created > b._created) {
          return 1;
        }
        else {
          return 0;
        }
      }).reverse();
    });
  }
}
</script>

<style scoped>
#newsgrid {
  margin-top: -20px;
}
</style>
