<template>
    <div class='traininginfo'>
      <h4>{{ data.StartZeit }} - {{ data.EndZeit }}</h4>
      <h2>{{ data.Artistenfamilie }}</h2>
      <h4>{{ data.Ort }}</h4>
      <h3>{{ data.Trainer }}</h3>
      <p v-if='data.Kommentar'>{{ data.Kommentar }}</p>
    </div>
</template>

<script>
export default {
  name: 'TrainingInfo',
  props: ['data']
}
</script>

<style>
.traininginfo {
  padding: 5px 10px;
  width: 100%;
}

.traininginfo h2 {
  font-family: 'Oswald-Medium', sans-serif;
  text-transform: uppercase;
  font-size: 1.0em;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 2px;
  margin-bottom: 2px;
}

.traininginfo h3 {
  font-family: 'NotoSans-Regular', sans-serif;
  font-size: 0.8em;
  font-weight: 100;
  line-height: 1.4em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.traininginfo h4 {
  font-family: 'Oswald-Medium', sans-serif;
  font-size: 0.8em;
  font-weight: 100;
  line-height: 1.3em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
}

.traininginfo p {
  font-family: 'NotoSans-Regular', sans-serif;
  font-size: 0.9em;
  font-weight: 100;
  line-height: 1.2em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>