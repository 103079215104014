<template>
  <main>
    <router-view :menu_items='menu_items' :pages='pages'/>
    <PartnerContent v-if='this.$route.path === "/portrait/partner"'/>
  </main>
</template>

<script>
import PartnerContent from '@/components/PartnerContent.vue'

export default {
  name: 'Portrait',
  props: ['menu_items', 'pages'],
  components: {
    PartnerContent
  }
}
</script>

<style>

</style>
