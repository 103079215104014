<template>
    <div/>
</template>

<script>
export default {
  name: 'BasePageDecoration',
}
</script>

<style>
@font-face {
  font-family: 'Oswald-Medium';
  src: url('../assets/font/Oswald-Medium.ttf');
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('../assets/font/Oswald-Regular.ttf');
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: url('../assets/font/NotoSans-Regular.ttf');
}

:root {
  --light-gray: #fefefefe;
  --medium-gray: #f1f1f1;
  --dark-gray: #555;
  --clown-red: #7F1734;
  --clown-red-old: #e30613;
  --clown-yellow: #fce300;
}

html, body {
  min-height: 100%;
}

body {
  font-family: 'NotoSans-Regular', sans-serif;
  background: var(--medium-gray) url(../assets/body-background.png) repeat;
  color: var(--dark-gray);
  line-height: 1.7;
  padding: 10px;
}

#app {
  background: var(--light-gray);
  border-top: 5px solid var(--clown-red);
  border-radius: 5px;
  box-shadow: 0 0 15px 0 #c6c6c6;
  padding: 20px;
  width: 90%;
  max-width: 1000px;
  margin: auto;
}

polyline {
  stroke-width: 3;
}

.row {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.grid {
  flex: 0 0 auto;
  width: calc(100% + 20px);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -10px -10px;
}

.fullwidth {
  margin-left: -20px;
  margin-right: -20px;
}

.rounded {
  border-radius: 5px;
}

.toprounded {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

</style>
