<template>
  <div class='row'>
    <article>
      <div class='page-content'>
        <h1> {{ article_title }} </h1>
        <BannerSlideshow v-if='this.$route.path === "/training/orlandinos"'
          :collection='"Slideshow"'
          :tag='"Orlandinos"'
          :height='"600px"'
        ></BannerSlideshow>
        <BannerSlideshow v-if='this.$route.path === "/training/variete"'
          :collection='"Slideshow"'
          :tag='"Variete"'
          :height='"600px"'
        ></BannerSlideshow>
        <div v-html='article_content'></div>
        <PartnerContent v-if='this.$route.path === "/gala2023/sponsoring"'/>
      </div>
    </article>
  </div>
</template>

<script>
import BannerSlideshow from '@/components/BannerSlideshow.vue'
import PartnerContent from '@/components/PartnerContent.vue'

export default {
  name: 'ArticleContent',
  props: ['menu_items', 'pages'],
  components: {
    BannerSlideshow,
    PartnerContent
  },
  computed: {
    current_page: function () {
      var current_menu_item = this.menu_items.find(item => item.Link === this.$route.path)
      if (!current_menu_item) return null
      if (!current_menu_item.Inhalt) return null
      if (!this.pages) return null
      return this.pages.find(page => page._id === current_menu_item.Inhalt._id)
    },
    article_title: function () {
      return this.current_page ? this.current_page.Titel : ""
    },
    article_content: function () {
      return this.current_page ? this.current_page.Inhalt : ""
    }
  }
}
</script>

<style>

article {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
}

article h1 {
  font-family: 'Oswald-Medium', sans-serif;
  color: var(--clown-red);
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
}

article h2 {
  font-family: 'Oswald-Medium', sans-serif;
  color: var(--clown-red);
  font-size: 1.4em;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}

article a {
  text-decoration: none;
  color: var(--clown-red);
}

</style>