<template>
    <div class='banner' :style='{height: height}'>
      <a @click='decrease_index()' class='slideshowprevious'>&lt;</a>
      <a @click='increase_index()' class='slideshownext'>&gt;</a>
      <div v-if='images.length > 0'>
        <transition name='bannerslide' mode='out-in'>
          <div class='slideshowimage' :style='{"background-image": "url(" + images[current_index].src + ")"}' :key='current_index'/>
        </transition>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BannerSlideshow',
  props: ['collection', 'tag', 'height'],
  data: function () {
    return {
      current_index: 0,
      images: [],
      timer: null
    }
  },
  created: function () {
    var vm = this;
        
    // Fetch slideshow images
    fetch('https://zirkus-orlando.de/cockpit/api/collections/get/' + vm.collection + '?token=125a1aa046027a483fa93c45e4fc2a')
    .then(res => res.json())
    .then(function(data) {
      // console.log(data)
      for (var entry of data.entries) {
        if (entry.Tags.includes(vm.tag)) {
          var image = new Image(); // prefetch
          image.src = "https://zirkus-orlando.de/cockpit/api/cockpit/image?token=125a1aa046027a483fa93c45e4fc2a&src=" + entry.Bild.path + "&w=1000&m=bestFit&o=true";
          vm.images.push(image);
        }
      }
    });

    this.timer = setInterval(this.increase_index, 5000);
  },
  methods: {
    increase_index: function () {
      if (this.current_index + 1 >= this.images.length) {
        this.current_index = 0;
      }
      else {
        this.current_index += 1;
      }
      clearInterval(this.timer);
      this.timer = setInterval(this.increase_index, 5000);
    },
    decrease_index: function () {
      if (this.current_index - 1 < 0) {
        this.current_index = this.images.length - 1;
      }
      else {
        this.current_index -= 1;
      }
      clearInterval(this.timer);
      this.timer = setInterval(this.increase_index, 5000);
    },
  },
  beforeUnmount () {
    clearInterval(this.timer);
  }
}
</script>

<style scoped>
.banner {
  overflow: hidden;
  border-radius: 5px;
  /* height: 500px; */
  margin-top: 20px;
  position: relative;
  background-color: #000000;
  backface-visibility: hidden;
}

.slideshowimage {
  background-size: cover;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.slideshowprevious, .slideshownext {
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: calc(50% - 16px);
  width: auto;
  padding: 4px 12px;
  color: var(--dark-gray);
  background-color: var(--medium-gray);
  font-weight: bold;
  font-size: 36;
  font-family: 'NotoSans-Regular', sans-serif;
  user-select: none;
  border-radius: 5px;
  z-index: 2;
}

.slideshowprevious:hover, .slideshownext:hover {
  color: var(--clown-red);
}

.slideshowprevious {
  left: 10px;
}

.slideshownext {
  right: 10px;
}

.bannerslide-enter-active, .bannerslide-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}

.bannerslide-enter-from, .bannerslide-leave-to {
  opacity: 0.1;
  transform: translateX(50px) scale(0.95);
}

.bannerslide-enter-to, .bannerslide-leave-from {
  opacity: 1.0;
  transform: translateX(0px) scale(1);
}

@media (max-width: 900px) {
  .banner {
    max-height: 400px;
  }
}

@media (max-width: 725px) {
  .banner {
    max-height: 300px;
  }
}
</style>